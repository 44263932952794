import React from 'react'
import DonationHeader from '../components/donation/DonationHeader'
import DonationForm from '../components/donation/DonationForm'

const Donation = () => {
  return (
    <>
      {/* <DonationHeader/> */}
      <DonationForm/>
    </>
  )
}

export default Donation
