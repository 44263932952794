import React from 'react'

const Coming_Soon = () => {
  return (
    <>
      <div
        className="about-us-section-area"
      >
        <div className="container">
          <div>
            <img src='img/gallery/coming-soon.jpg'/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Coming_Soon
